import { deviceEnquire, DEVICE_TYPE } from '@/utils/device'
import { mapState } from 'vuex'
const AppDeviceEnquire = {
  mounted() {
    const { $store } = this
    deviceEnquire((deviceType) => {
      switch (deviceType) {
        case DEVICE_TYPE.DESKTOP:
          $store.commit('TOGGLE_DEVICE', 'desktop')
          break

        case DEVICE_TYPE.LAPTOP:
          $store.commit('TOGGLE_DEVICE', 'laptop')
          break

        case DEVICE_TYPE.TABLET:
          $store.commit('TOGGLE_DEVICE', 'tablet')
          break

        case DEVICE_TYPE.MOBILE:
        default:
          $store.commit('TOGGLE_DEVICE', 'mobile')
          break
      }
    })
  },
  computed: {
    ...mapState({
      $device: (state) => state.app.device,
    }),
  },
}
const toolMixin = {
  filters: {
    $text(value) {
      return value.replace(/<\/?.+?\/?>/g, '')
    },
  },
  methods: {
    $resetableData(dataFn) {
      this._cacheDataFn_ = dataFn
      return dataFn(this.$createElement)
    },

    // 要在包含setup中使用reset（setup 的data 包含2个部分），需要用resetableData包裹data
    $reset(rootDataFieldName) {
      if (rootDataFieldName) {
        const dataFn = this._cacheDataFn_ || this.$options.data
        this[rootDataFieldName] = dataFn.call(this)[rootDataFieldName]
      } else {
        Object.assign(this.$data, dataFn.call(this))
      }
    },
  },
}
export { AppDeviceEnquire, toolMixin }
