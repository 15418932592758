<template>
  <a-modal
    :title="modal.title"
    class="modal-scroll"
    v-model="modal.visible"
    @ok="handleOk"
    @cancel="handleCancel"
    :width="800"
  >
    <div class="text-2xl">
      <p class="indent-2">
        净化万泰组织内部生态，构建美好组织，是万泰矢志不渝的目标，要达成这个目标，需要全体员工的参与，希望广大员工、客户、供应商积极投诉，为净化万泰的生态、构建万泰美好组织贡献一份力量
      </p>
      <p class="font-bold mt-8">CEO投诉通道：</p>
      <p class="indent-2"><span class="text-black">邮箱：</span></p>
      <p class="indent-2"><a href="mailto:zjb@cqvantai.com" target="_blank">zjb@cqvantai.com</a></p>
      <p class="indent-2"><a href="mailto:ts@cqvantai.com" target="_blank">ts@cqvantai.com</a></p>
      <p class="indent-2"><a href="mailto:269313239@qq.com" target="_blank">269313239@qq.com</a></p>
      <p class="indent-2">
        <span class="text-black">书信投诉快递地址：</span>
        重庆市渝北区金州大道98号 重庆万泰建设（集团）有限公司 高宗银（亲启）
      </p>
    </div>
  </a-modal>
</template>

<script>
import { toolMixin } from '@/utils/mixin'
export default {
  mixins: [toolMixin],
  data() {
    return {
      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '总经理投诉通道',
        data: {},
      },
    }
  },
  methods: {
    async start() {
      this.$reset('modal')
      this.modal.visible = true
      return new Promise((resolve, reject) => {
        this.$once('end', function (state, result) {
          if (state === 'ok') {
            resolve(result)
          }
          if (state === 'cancel') {
            reject(new Error('E_CANCEL'))
          }
          this.modal.visible = false
        })
      })
    },
    async handleOk() {
      this.$emit('end', 'ok')
    },
    handleCancel() {
      this.$emit('end', 'cancel')
    },
  },
}
</script>

<style scoped lang="less">
.indent-2 {
  text-indent: 2em;
}
</style>
