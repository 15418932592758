import { app } from '@/main'
import UrlHandler from '@/utils/urlHandle'
import { isIE } from '@/utils/util'
export const menuNavigate = (menu) => {
  if (menu.meta.url && menu.meta.url.match(/^http:\/\/erp/) && !isIE()) {
    // eslint-disable-next-line
    const h = app.$createElement
    app.$info({
      title: '系统提示',
      content: (
        <div>
          <p>您当前访问的链接属于erp系统，请复制链接到IE内核(IE11以上)浏览器打开</p>
          <p>
            <a
              onClick={async () => {
                app.$copyText(generateSsoUrl(menu.meta.url))
                app.$message.success('复制成功')
              }}
            >
              点击复制链接
            </a>
          </p>
        </div>
      ),
    })
    return
  }
  if (menu.meta.target === 'blank') {
    open(menu.meta.url)
  } else {
    app.$router.push(menu.path)
  }
}
export const generateSsoUrl = (url) => {
  const token = app.$store.state.user.auth.accessToken
  const uh = new UrlHandler(url)

  const targetUrl = uh.build({
    token,
  })
  return targetUrl
}
export const go = (url, replace = true) => {
  const ssoUrl = generateSsoUrl(url)
  if (replace) {
    window.location.replace(ssoUrl)
  } else {
    window.location.href = ssoUrl
  }
}
export const open = (url, direct = true) => {
  if (direct) {
    // 直接跳转
    const ssoUrl = generateSsoUrl(url)
    window.open(ssoUrl)
  } else {
    // 跳转到redirect统一处理
    const a = document.createElement('a')
    a.href = `/redirect?url=${encodeURIComponent(url)}`
    a.click()
    // window.open()
  }
}
