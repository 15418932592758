<script lang="jsx">
import treeUtils from '@/utils/treeUtils'
import { menuNavigate } from '@/service/navigate'
export default {
  name: 'SideMenu',
  props: {
    menus: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeKeys: [],
      openKeys: [],
    }
  },
  computed: {
    collapse() {
      return this.$store.state.app.sidebarCollapse
    },
  },
  mounted() {
    // this.initOpenKeys()
    this.updateMenuActive()
  },
  watch: {
    $route: function () {
      this.updateMenuActive()
    },
    menus: {
      immediate: true,
      handler() {
        this.initOpenKeys()
      },
    },
  },
  methods: {
    initOpenKeys() {
      const defaultOpenKeys = []
      // 默认展开第一个菜单
      // let children = this.menus
      // while (children) {
      //   if (children[0]) {
      //     defaultOpenKeys.push(children[0].path)
      //     children = children[0].children
      //     continue
      //   }
      //   break
      // }
      // 展开所有
      treeUtils.forEach(this.menus, (m) => {
        if (m.children) {
          defaultOpenKeys.push(m.path)
        }
      })
      this.openKeys = defaultOpenKeys
    },

    updateMenuActive() {
      const activeRoutes = treeUtils.findAllParents(this.menus, (m) => {
        return m.path === this.$route.path
      })
      activeRoutes.shift()
      const routes = []
      activeRoutes.map((route) => {
        routes.push({
          meta: route.meta,
          path: route.path,
        })
      })
      routes.push({
        path: this.$route.path,
        meta: this.$route.meta,
      })
      this.activeKeys = routes.map((route) => {
        return route.path
      })
    },

    renderMenuItem(menu, level = 1) {
      let isActive = this.activeKeys.includes(menu.path)
      const isExactActive = menu.path === this.activeKeys[this.activeKeys.length - 1]
      const isOpen = this.openKeys.includes(menu.path)
      const subMenuItems = []

      if (menu.children) {
        this.activeKeys.map((key) => {
          for (let i = 0; i < menu.children.length; i++) {
            if (key.startsWith(menu.children[i].path)) {
              isActive = true
            }
          }
        })
        for (let i = 0; i < menu.children.length; i++) {
          if (!menu.children[i].meta.hidden) {
            subMenuItems.push(this.renderMenuItem(menu.children[i], level + 1))
          }
        }
      }
      const onClick = (e) => {
        menuNavigate(menu)
      }
      const onClickIcon = (e) => {
        const openKeys = [...this.openKeys]
        const openkeyIndex = openKeys.findIndex((key) => key === menu.path)
        if (openkeyIndex > -1) {
          openKeys.splice(openkeyIndex, 1)
        } else {
          openKeys.push(menu.path)
        }
        this.openKeys = openKeys
        e.stopPropagation()
      }
      if (!menu.meta.hidden) {
        return (
          <div
            class={[
              'menu-item',
              `menu-item-level-${level}`,
              isActive ? 'active' : '',
              isExactActive ? 'exact-active' : '',
              subMenuItems.length ? 'has-children' : '',
              isOpen ? 'list-expanded' : '',
            ]}
            ref={menu.path}
          >
            <div
              class={['title', menu.meta.type === 'M' ? 'menu-m' : 'menu-c']}
              onClick={(e) => {
                if (menu.meta.type === 'C') {
                  onClick(e)
                }
              }}
            >
              {level === 1 && <span class="title-icon">{this.renderIcon(menu.meta.icon || 'appstore-o')}</span>}
              {level === 3 && <span class="title-icon"></span>}
              <span class="title-text" title={menu.meta.title}>
                {menu.meta.title}
              </span>

              {subMenuItems.length > 0 && (
                <span
                  class="right-icon"
                  onClick={(e) => {
                    onClickIcon(e)
                  }}
                >
                  <app-icon type={isOpen ? 'icon-shangjiantou' : 'icon-youjiantou'}></app-icon>
                </span>
              )}
            </div>
            {subMenuItems.length > 0 && <div class={['sub-menu-list']}>{subMenuItems}</div>}
          </div>
        )
      }
    },
    generateMenuTree(menu) {
      if (!menu.length) {
        return <div class="menu-empty">暂无内容</div>
      }
      const rootMenuTree = []
      menu.map((m) => {
        rootMenuTree.push(this.renderMenuItem(m))
      })
      return <div class="menu-list">{rootMenuTree}</div>
    },
    renderIcon(icon) {
      if (!icon || icon === 'none') {
        return null
      }
      const props = {}
      typeof icon === 'object' ? (props.component = icon) : (props.type = icon)
      return <a-icon {...{ props }} />
    },
    changeSidebarCollapse(state) {
      this.$store.commit('SIDEBAR_COLLAPSE', state)
    },
  },

  render() {
    const menuTree = this.generateMenuTree(this.menus)
    return (
      <a-layout-sider class="site-menu" width={this.collapse ? 300 : 0}>
        <div class="menu-top">
          <img src={require('@/assets/images/common/logo.png')} class="menu-logo" />
          <div
            class="sidebar-collapse-btn-fold cursor-pointer"
            onClick={() => {
              this.changeSidebarCollapse(false)
            }}
          >
            <a-icon type="menu-fold" class="block" />
          </div>
          <div
            class={['sidebar-collapse-btn-unfold', this.collapse ? '' : 'active']}
            onClick={() => {
              this.changeSidebarCollapse(true)
            }}
          >
            <a-icon type="right"></a-icon>
          </div>
        </div>
        {menuTree}
      </a-layout-sider>
    )
  },
}
</script>
