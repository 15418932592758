import Vue from 'vue'
import router from '@/router'
const createRandomChar = function (num) {
  let char = ''
  for (let i = 0; i < num; i++) {
    char += String.fromCharCode(Math.floor(Math.random() * 26) + 'a'.charCodeAt(0))
  }
  return char
}

const app = {
  state: {
    device: 'desktop',
    loaded: true,
    sidebarCollapse: true,
    keepAliveInclude: [],
    keepAliveRouteMap: {},
    tab: [], // 进入菜单项会自动添加到tab，route.meta.flat的会添加到tab
    nextRefreshKeepAlive: '', // routePath
  },
  mutations: {
    SIDEBAR_COLLAPSE(state, isCollapse) {
      state.sidebarCollapse = isCollapse
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    SET_NEXT_REFRESH_KEEP_ALIVE(state, path) {
      state.nextRefreshKeepAlive = path
    },
    UPDATE_KEEP_ALIVE_INCLUDE: (state, keepAliveInclude) => {
      state.keepAliveInclude = keepAliveInclude
    },
    SET_KEEP_ALIVE_ROUTE_MAP: (state, { path, name }) => {
      state.keepAliveRouteMap[path] = name
    },
    UPDATE_LOADED(state, loaded) {
      state.loaded = loaded
    },
    SET_TAB(state, currentTab) {
      let tab = [...state.tab]
      const matched = tab.find((tab) => tab.path === currentTab.path)
      if (matched) {
        matched.currentPath = currentTab.currentPath
      } else {
        tab = [...tab, currentTab]
      }
      state.tab = tab
    },
    REMOVE_TAB(state, path) {
      const tab = [...state.tab]
      const index = tab.findIndex((tab) => {
        return tab.path === path
      })
      tab.splice(index, 1)
      state.tab = tab
    },
  },
  actions: {
    async ReloadRoute({ state, commit, dispatch }, path) {
      // 当前路由才需要重载
      if (path === router.currentRoute.fullPath) {
        commit('UPDATE_LOADED', false)
      }
      // 如果是已缓存的则更新
      if (state.keepAliveRouteMap[path]) {
        commit('SET_NEXT_REFRESH_KEEP_ALIVE', path)
        await dispatch('SetKeepAlive', path)
      }
      if (path === router.currentRoute.fullPath) {
        commit('UPDATE_LOADED', true)
      }
    },
    async SetKeepAlive({ commit, state }, path) {
      if (!state.keepAliveRouteMap[path]) {
        const name = 'keepAliveRoute-' + createRandomChar(5) + new Date().getTime()
        Vue.component(name, { render: (h) => <router-view /> })
        commit('SET_KEEP_ALIVE_ROUTE_MAP', { path, name })
      }
      const keepAliveInclude = [...state.keepAliveInclude]

      const index = keepAliveInclude.findIndex((v) => v === path)
      // 如果存在的话，判断当前需不需要刷新；不存在，则直接在后面添加
      if (index > -1) {
        // 如果需要刷新，先删除掉当前的，再在同一位置通过setTimeout让视图重新渲染，再把删除掉的重新添加进去。
        if (state.nextRefreshKeepAlive) {
          keepAliveInclude.splice(index, 1)
          commit('UPDATE_KEEP_ALIVE_INCLUDE', [...keepAliveInclude])
          await new Promise((resolve) => {
            setTimeout(resolve)
          })
          keepAliveInclude.splice(index, 0, path)
          commit('UPDATE_KEEP_ALIVE_INCLUDE', [...keepAliveInclude])
        }
      } else {
        commit('UPDATE_KEEP_ALIVE_INCLUDE', [...keepAliveInclude, path])
      }
      // console.log(state)
      // 最后都需要重置刷新标识
      commit('SET_NEXT_REFRESH_KEEP_ALIVE', '')
    },
    async RemoveKeepAlive({ commit, state }, path) {
      const keepAliveInclude = [...state.keepAliveInclude]
      const index = keepAliveInclude.findIndex((key) => key === path)
      if (index > -1) {
        keepAliveInclude.splice(index, 1)
        commit('UPDATE_KEEP_ALIVE_INCLUDE', keepAliveInclude)
      }
    },
    ToggleDevice({ commit }, device) {
      commit('TOGGLE_DEVICE', device)
    },
  },
}

export default app
