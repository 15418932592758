<script lang="jsx">
import treeUtils from '@/utils/treeUtils'
import { menuNavigate } from '@/service/navigate'
export default {
  name: 'SideWorkMenu',
  data() {
    return {
      activeKeys: [],
      openKeys: [],
      menus: [],
    }
  },
  computed: {
    collapse() {
      return this.$store.state.app.sidebarCollapse
    },
  },
  mounted() {
    // this.initOpenKeys()
    // this.updateMenuActive()
  },
  watch: {
    $route: function () {
      // this.updateMenuActive()
    },
    menus: {
      immediate: true,
      handler() {
        this.initOpenKeys()
      },
    },
    '$store.state.user.collectionMenuIds': {
      immediate: true,
      handler() {
        this.initMenus()
      },
    },
  },
  methods: {
    initMenus() {
      // const workbenchMenus = .find((item) => item.meta.code === 'gongzuotai')
      const collectionMenus = treeUtils.filter(
        this.$store.state.permission.menus,
        (menu) => {
          return this.$store.state.user.collectionMenuIds.includes(menu.meta.extra?.id)
        },
        { root: true, leaf: true }
      )
      const flatCollectionTree = collectionMenus.map((a) => {
        return {
          ...a,
          children: [],
        }
      })
      console.log(collectionMenus)
      console.log(JSON.parse(JSON.stringify(flatCollectionTree)))
      treeUtils.forEach(collectionMenus, (m, indexId) => {
        const indexArr = indexId.split('-')
        if (indexArr.length === 2) {
          // m.meta.extra.displayType = 'collection'
          const children = []
          // m.children = []
          treeUtils.forEach(m.children, (mm, indexId2) => {
            const indexArr2 = indexId2.split('-')
            if (indexArr2.length === 2) {
              mm.meta.extra.displayType = 'collection'
              children.push(mm)
            }
          })
          m.children = children
          flatCollectionTree[indexArr[0]].children.push(m)
        }
      })
      const todoList = this.$store.state.user.todoList.map((m, index) => {
        return {
          path: `/m/_todo${index}_`,
          meta: {
            title: m.title,
            icon: '',
            type: 'C',
            code: `_todo${index}_`,
            url: m.url,
            target: 'blank',
            actions: [],
            extra: {},
          },
        }
      })
      const menus = [
        {
          path: '/m/_todo_',
          meta: {
            title: '流程管理',
            icon: 'unordered-list',
            type: 'M',
            code: '_todo_',
            url: '',
            target: '',
            actions: [],
            extra: {},
          },
          children: todoList,
        },
        {
          path: '/m/_collection_',
          meta: {
            title: '我的收藏',
            icon: 'star-o',
            type: 'M',
            code: '_collection_',
            url: '',
            target: '',
            actions: [],
            extra: {},
          },
          children: flatCollectionTree,
        },
      ]
      this.menus = menus
    },
    initOpenKeys() {
      const defaultOpenKeys = []
      treeUtils.forEach(this.menus, (m) => {
        if (m.children) {
          defaultOpenKeys.push(m.path)
        }
      })
      this.openKeys = defaultOpenKeys
    },

    updateMenuActive() {
      const activeRoutes = treeUtils.findAllParents(this.menus, (m) => {
        return m.path === this.$route.path
      })
      activeRoutes.shift()
      const routes = []
      activeRoutes.map((route) => {
        routes.push({
          meta: route.meta,
          path: route.path,
        })
      })
      routes.push({
        path: this.$route.path,
        meta: this.$route.meta,
      })
      this.activeKeys = routes.map((route) => {
        return route.path
      })
    },

    renderMenuItem(menu, level = 1) {
      let isActive = this.activeKeys.includes(menu.path)
      const isExactActive = menu.path === this.activeKeys[this.activeKeys.length - 1]
      const isOpen = this.openKeys.includes(menu.path)
      const subMenuItems = []

      if (menu.children) {
        this.activeKeys.map((key) => {
          for (let i = 0; i < menu.children.length; i++) {
            if (key.startsWith(menu.children[i].path)) {
              isActive = true
            }
          }
        })
        for (let i = 0; i < menu.children.length; i++) {
          if (!menu.children[i].meta.hidden) {
            subMenuItems.push(this.renderMenuItem(menu.children[i], level + 1))
          }
        }
      }
      const onClick = (e) => {
        menuNavigate(menu)
      }
      const onClickIcon = (e) => {
        const openKeys = [...this.openKeys]
        const openkeyIndex = openKeys.findIndex((key) => key === menu.path)
        if (openkeyIndex > -1) {
          openKeys.splice(openkeyIndex, 1)
        } else {
          openKeys.push(menu.path)
        }
        this.openKeys = openKeys
        e.stopPropagation()
      }
      const onClickCollectionCancel = (e) => {
        this.$axios
          .post('/unify-home/home/menu/delete', {
            mid: menu.meta.extra.id,
          })
          .then(() => {
            this.$message.success('已取消')
            this.$store.dispatch('UpdateCollectionMenus')
          })

        e.stopPropagation()
      }
      if (!menu.meta.hidden) {
        return (
          <div
            class={[
              'menu-item',
              `menu-item-level-${level}`,
              isActive ? 'active' : '',
              isExactActive ? 'exact-active' : '',
              subMenuItems.length ? 'has-children' : '',
              isOpen ? 'list-expanded' : '',
            ]}
            ref={menu.path}
          >
            <div
              class={['title', menu.meta.type === 'M' ? 'menu-m' : 'menu-c']}
              onClick={(e) => {
                if (menu.meta.type === 'C') {
                  onClick(e)
                }
              }}
            >
              {level === 1 && <span class="title-icon">{this.renderIcon(menu.meta.icon || 'appstore-o')}</span>}
              {level === 3 && <span class="title-icon"></span>}
              <span class="title-text" title={menu.meta.title}>
                {menu.meta.title}
              </span>

              {subMenuItems.length > 0 && (
                <span
                  class="right-icon"
                  onClick={(e) => {
                    onClickIcon(e)
                  }}
                >
                  <app-icon type={isOpen ? 'icon-shangjiantou' : 'icon-youjiantou'}></app-icon>
                </span>
              )}
              {menu.meta.extra.displayType === 'collection' && (
                <span
                  class="right-icon collection-cancel-icon"
                  onClick={(e) => {
                    onClickCollectionCancel(e)
                  }}
                >
                  <a-icon type="close"></a-icon>
                </span>
              )}
            </div>
            {subMenuItems.length > 0 && <div class={['sub-menu-list']}>{subMenuItems}</div>}
          </div>
        )
      }
    },
    generateMenuTree(menu) {
      if (!menu.length) {
        return (
          <div class="menu-empty">
            <a-icon type="star-o" class="mr-1"></a-icon>暂无收藏
          </div>
        )
      }
      const rootMenuTree = []
      menu.map((m) => {
        rootMenuTree.push(this.renderMenuItem(m))
      })
      return <div class="menu-list">{rootMenuTree}</div>
    },
    renderIcon(icon) {
      if (!icon || icon === 'none') {
        return null
      }
      const props = {}
      typeof icon === 'object' ? (props.component = icon) : (props.type = icon)
      return <a-icon {...{ props }} />
    },
    changeSidebarCollapse(state) {
      this.$store.commit('SIDEBAR_COLLAPSE', state)
    },
  },

  render() {
    const menuTree = this.generateMenuTree(this.menus)
    return (
      <a-layout-sider class="site-menu" width={this.collapse ? 300 : 0}>
        <div class="menu-top">
          <img src={require('@/assets/images/common/logo.png')} class="menu-logo" />
          <div
            class="sidebar-collapse-btn-fold cursor-pointer"
            onClick={() => {
              this.changeSidebarCollapse(false)
            }}
          >
            <a-icon type="menu-fold" class="block" />
          </div>
          <div
            class={['sidebar-collapse-btn-unfold', this.collapse ? '' : 'active']}
            onClick={() => {
              this.changeSidebarCollapse(true)
            }}
          >
            <a-icon type="right"></a-icon>
          </div>
        </div>
        {menuTree}
      </a-layout-sider>
    )
  },
}
</script>
