import { permissionMenuRoutes, asyncRouterMapRoot, processedDefaultMenus } from '@/routes'
import treeUtils from '@/utils/treeUtils'
/**
 *
 * @param {*} apiMenus 基于原始接口数据做处理
 * @returns 基于route的menuData
 */
function menuAdaptor(menus) {
  return treeUtils.map(menus, (item) => {
    return {
      // 菜单基础信息
      menuUrl: item.mUrl || '',
      menuName: item.mName || '',
      menuIcon: item.mPic || '',
      menuType: item.typeOn || '',
      menuCode: item.mPyCode, //  唯一值
      menuTarget: item.typeUrl === 'WB' ? 'blank' : '',
      menuHidden: item.mHide,

      // 额外信息
      id: item.mId,
      remark: item.remark,
    }
  })
}
/**
 *
 * @param {*} apiMenus 基于menuAdator数据的menu
 * @returns 基于route的menuData
 */
function getMenuData(apiMenus) {
  let menuData = treeUtils.map(apiMenus, (item) => {
    const uniq = item.menuCode
    // path必须是标准的vue路由path且唯一,url为原始配置地址。
    let path = encodeURI(`/m/__${uniq}__`)
    let url = item.menuUrl
    // 如果填写的是标准路径，直接使用，且不再解析原始配置地址
    if (item.menuUrl.startsWith('/')) {
      path = item.menuUrl
      url = ''
    }

    const actions = []
    if (item.children) {
      item.children.forEach((child) => {
        if (child.menuType === 'A') {
          actions.push({
            name: child.menuName,
            code: child.menuCode,
          })
        }
      })
      // 指定哪些菜单为目录（需要重定向的）
      // if (item.children.some((child) => child.menuType === 'C')) {
      //   item.menuType = 'M'
      // }
    }
    return {
      path: path,
      meta: {
        title: item.menuName,
        icon: item.menuIcon,
        type: item.menuType,
        code: item.menuCode,
        url: url,
        target: item.menuTarget,
        actions,
        hidden: item.menuHidden,
        extra: {
          id: item.id,
          remark: item.remark,
        },
      },
    }
  })
  menuData = treeUtils.filter(menuData, (item) => {
    return item.menuType !== 'A'
  })
  return menuData
}

/**
 * 创建用户的menu,并用预设的前端menu补充信息
 * 本地路由集中有的，但远程菜单没有的，会自动添加hidden
 * @param {*} localRouteMap 本地路由集
 * @param {*} menu 用户菜单
 */
function processPermissionMenus(localRouteMap, menu) {
  const r = {}
  treeUtils.forEach(localRouteMap, (route) => {
    if (route.path) {
      r[route.path] = {
        ...route,
      }
    }
  })
  // 补全menu信息(会添加子路由，但是只循环menu)
  treeUtils.forEach(
    menu,
    (m) => {
      const localRoute = r[m.path]
      // 存在本地路由
      if (localRoute) {
        for (const j in localRoute.meta) {
          if (!m.meta[j]) {
            m.meta[j] = localRoute.meta[j]
          }
        }

        m.component = localRoute.component
        if (localRoute.children) {
          // 菜单中隐藏本地路由
          treeUtils.forEach(localRoute.children, (child) => {
            child.meta.hidden = true
          })
          m.children = localRoute.children
          m.skip = true
        }
        if (localRoute.redirect) {
          m.redirect = localRoute.redirect
        }
      }
    },
    {
      skip: (item) => {
        return item.skip
      },
    }
  )
  const pageWebview = () => import('@/pages/Webview')
  const page404 = () => import('@/pages/exception/404')
  // 补全redirect和component(循环menu 和添加的 子路由)
  treeUtils.forEach(menu, (m) => {
    // 处理无法解析到路由的url
    if (m.meta.url) {
      // 如果是需要外部打开的，则重定向；否则使用webview打开
      if (m.meta.target === 'blank') {
        m.redirect = {
          path: '/redirect',
          query: {
            url: m.meta.url,
          },
        }
      } else {
        m.component = pageWebview
      }
    }
    // 如果是目录，自动添加重定向到下级
    if (m.meta.type === 'M' && !m.redirect) {
      const activeChild = m.children?.find((routeChild) => !routeChild.meta.hidden)
      if (activeChild) {
        m.redirect = activeChild.path
      }
    }

    if (!(m.component || m.redirect)) {
      m.component = page404
    }
  })
  return menu
}
/**
 *
 * @param {*} menus 原始接口数据
 * @returns
 */
export function generateMenus(menus) {
  // menuData 只包含menu数据
  const menuData = getMenuData(menuAdaptor(menus))
  // 基于menuData 创建完整的routes 包含 redirect / component / meta
  const processedPermissionMenus = processPermissionMenus(permissionMenuRoutes, menuData)
  return processedPermissionMenus.concat(processedDefaultMenus)
}

export function generateAccessRouters(menus, routerMap = asyncRouterMapRoot) {
  const accessRouters = []
  const blankAccessRouters = []
  const rootPermissionRouters = routerMap.find((v) => v.path === '/m')
  const rootBlankPermissionRoutes = routerMap.find((v) => v.path === '/b')

  treeUtils.forEach(menus, (route) => {
    const { children, ...r } = route
    if (r.path) {
      accessRouters.push(r)
      blankAccessRouters.push({
        ...r,
        path: `/b${r.path}`,
        redirect: `/b${route.redirect}`,
      })
    }
  })

  rootPermissionRouters.children = accessRouters
  rootBlankPermissionRoutes.children = blankAccessRouters
  return routerMap
}
