<template>
  <div class="app-autoImg">
    <AutoImg @load="onLoad" :src="src" :fit="fit" style="width: 100%; height: 100%">
      <template #error>
        <div class="autoImg-error" title="加载图片失败">
          <svg class="autoImg-error-text" viewBox="0 0 85 20">
            <text x="0" y="16" fill="#666">加载图片失败</text>
          </svg>
        </div>
      </template>
      <template #loading><a-icon type="loading"></a-icon></template>
    </AutoImg>
  </div>
</template>

<script>
import AutoImg from './AutoImg'
export default {
  data() {
    return {
      loaded: false,
    }
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    fit: {
      type: String,
      default: '',
    },
  },
  components: {
    AutoImg,
  },
  methods: {
    onLoad() {
      this.loaded = true
    },
  },
}
</script>

<style lang="less" scoped>
.app-autoImg {
  position: relative;
  display: inline-block;
  border-radius: 5px;
  overflow: hidden;
  .autoImg-error {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .autoImg-error-text {
      width: 80%;
      max-width: 100px;
      pointer-events: none;
    }
  }
}
</style>
