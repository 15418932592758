// eslint-disable-next-line
import { BasicLayout, BlankLayout, RouterView } from '@/layouts'

export const asyncRouterMapRoot = [
  {
    path: '/m',
    component: BasicLayout,
    // 动态添加
    children: [],
  },
  {
    path: '/b',
    component: RouterView,
    // 动态添加
    children: [],
  },
]
// permissionMenuRoutes为用户权限菜单，用户有权限，才会展示其中的某些菜单
// 路由path是唯一值，不能重复，menu视图建议/m开头
// NOTE 目录只有本身路由，可简写（不用写RouterView重定向到下级）
export const permissionMenuRoutes = [
  {
    path: '/m/home',
    meta: {
      title: '综合门户',
    },
    component: () => import('@/pages/scopes/main/Home'),
  },
  {
    path: '/m/workbench',
    meta: {
      title: '工作台',
    },
    component: () => import('@/pages/scopes/main/Workbench'),
  },
  {
    path: '/m/dataSearch',
    meta: {
      title: '数据查询',
    },
    component: () => import('@/pages/scopes/main/DataSearch'),
  },
  {
    path: '/m/appList',
    meta: {
      title: '应用系统',
    },
    component: () => import('@/pages/scopes/main/AppList'),
  },
]

// defaultMenus数据结构同权限路由组装以后的结果permissionMenus(->见permission.js)，这里可直接添加到constantRouterMap
// 路由path命名需要唯一！带redirect的中转路由，只要关注redirect就好了。
export const processedDefaultMenus = [
  {
    path: '/m/hidden',
    meta: {
      title: '',
      hidden: true,
    },
    redirect: '/m/todo',
    children: [
      {
        path: '/m/todo',
        meta: {
          title: '流程管理',
        },
        component: BasicLayout,
        redirect: '/m/_todo0_',
        children: [
          {
            path: '/m/_todo0_',
            meta: {
              title: '我的待办',
            },
            component: () => import('@/pages/Webview'),
          },
          {
            path: '/m/_todo1_',
            meta: {
              title: '我的待办',
            },
            component: () => import('@/pages/Webview'),
          },
          {
            path: '/m/_todo2_',
            meta: {
              title: '我的待办',
            },
            component: () => import('@/pages/Webview'),
          },
        ],
      },
    ],
  },

  // {
  //   path: '/m/d3',
  //   meta: {
  //     title: '工作台'
  //   },
  //   component: BasicLayout,
  //   redirect: '/m/workplace',
  //   children: [
  //     {
  //       path: '/m/workplace',
  //       meta: {
  //         title: '工作台',
  //         hidden: true
  //       },
  //       component: () => import('@/pages/Workplace')
  //     }
  //   ]
  // },
  // {
  //   path: '/m/d1',
  //   meta: {
  //     title: '静态目录1'
  //   },
  //   component: BasicLayout,
  //   redirect: '/m/d1/l1',
  //   children: [
  //     {
  //       path: '/m/d1/l1',
  //       meta: {
  //         title: '系统管理'
  //       },
  //       component: RouterView,
  //       redirect: '/m/home',
  //       children: [
  //         {
  //           path: '/m/home',
  //           meta: {
  //             title: '首页'
  //           },
  //           component: () => import('@/pages/Home')
  //         }
  //         // {
  //         //   path: '/m/menuManage',
  //         //   component: () => import('@/pages/MenuManage'),
  //         //   meta: {
  //         //     title: '菜单管理'
  //         //   }
  //         // },
  //         // {
  //         //   path: '/m/roleManage',
  //         //   component: () => import('@/pages/roleManage/RoleManage'),
  //         //   meta: {
  //         //     title: '角色管理'
  //         //   }
  //         // }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   path: '/m/d2',
  //   meta: {
  //     title: '静态目录2'
  //   },
  //   component: BasicLayout,
  //   redirect: '/m/d2/l1',
  //   children: [
  //     {
  //       path: '/m/d2/l1',
  //       meta: {
  //         title: '简单模块'
  //         // hideChildrenInMenu: true
  //       },
  //       component: RouterView,
  //       redirect: '/m/simplePage/main',
  //       children: [
  //         {
  //           path: '/m/simplePage/main',
  //           meta: {
  //             title: '主模块（列表页）'
  //           },
  //           component: RouterView,
  //           redirect: '/m/simplePage/main/list',
  //           children: [
  //             {
  //               path: '/m/simplePage/main/list',
  //               meta: {
  //                 title: '列表页',
  //                 hidden: true
  //               },
  //               component: () => import('@/pages/simplePage/main/List')
  //             },
  //             {
  //               path: '/m/simplePage/main/detail',
  //               meta: {
  //                 title: '详情页',
  //                 hidden: true
  //               },
  //               component: () => import('@/pages/simplePage/main/Detail')
  //             }
  //           ]
  //         },
  //         {
  //           path: '/m/simplePage/overview',
  //           meta: {
  //             title: '其他模块'
  //           },
  //           component: () => import('@/pages/simplePage/Overview')
  //         }
  //       ]
  //     },
  //     {
  //       path: '/m/d2/l2',
  //       meta: {
  //         title: 'keepAlive模块'
  //         // hideChildrenInMenu: true
  //       },
  //       component: RouterView,
  //       redirect: '/m/keepAlivePage/main',
  //       children: [
  //         {
  //           path: '/m/keepAlivePage/main',
  //           meta: {
  //             title: '主模块（列表页）'
  //           },
  //           component: RouterView,
  //           redirect: '/m/keepAlivePage/main/list',
  //           children: [
  //             {
  //               path: '/m/keepAlivePage/main/list',
  //               meta: {
  //                 title: '列表页',
  //                 keepAlive: true,
  //                 hidden: true
  //               },
  //               component: () => import('@/pages/keepAlivePage/main/List')
  //             },
  //             {
  //               path: '/m/keepAlivePage/main/detail',
  //               meta: {
  //                 title: '详情页',
  //                 hidden: true
  //               },
  //               component: () => import('@/pages/keepAlivePage/main/Detail')
  //             }
  //           ]
  //         },
  //         {
  //           path: '/m/keepAlivePage/overview',
  //           meta: {
  //             title: '其他模块'
  //           },
  //           component: () => import('@/pages/keepAlivePage/Overview')
  //         }
  //       ]
  //     }
  //   ]
  // }
]
export const constantRouterMap = [
  {
    path: '/user',
    component: RouterView,
    redirect: '/user/login',
    children: [
      {
        path: '/user/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/pages/user/Login'),
      },
    ],
  },
  {
    path: '/sso',
    component: () => import(/* webpackChunkName: "init" */ '@/pages/Sso'),
  },
  {
    meta: {
      title: '404',
    },
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/pages/exception/404'),
  },
  {
    meta: {
      title: '403',
    },
    path: '/403',
    component: () => import(/* webpackChunkName: "fail" */ '@/pages/exception/403'),
  },
  {
    meta: {
      title: 'redirect',
    },
    path: '/redirect',
    component: () => import(/* webpackChunkName: "fail" */ '@/pages/Redirect'),
  },
  {
    meta: {
      title: 'open',
    },
    path: '/open',
    component: () => import(/* webpackChunkName: "fail" */ '@/pages/Redirect'),
  },
  {
    meta: {
      title: 'webview',
    },
    path: '/webview',
    component: () => import(/* webpackChunkName: "fail" */ '@/pages/Redirect'),
  },
  // open 是redirect 的别名，主要用来区别是不是需要打开新窗口
]
