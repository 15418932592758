<template>
  <a-layout-header class="header">
    <div class="top-menu">
      <div
        class="top-menu-item"
        v-for="(item, index) in menus"
        :key="index"
        @click="menuClick(item)"
        :class="{ active: activeLevel1MenusIndex === index }"
        :hidden="item.meta.hidden"
      >
        {{ item.meta.title }}
      </div>
    </div>
    <div class="header-extra">
      <a-popover
        v-model="visible"
        trigger="hover"
        placement="bottomRight"
        overlayClassName="dropdown-extra-menu"
        :autoAdjustOverflow="true"
        :arrowPointAtCenter="true"
      >
        <template slot="content">
          <div class="dropdown-extra-menu-list">
            <div class="cursor-pointer dropdown-extra-menu-list">
              <div class="menu-label" @click="openDemand">
                <a-icon type="container"></a-icon>
                <span class="label-text">我要提需求</span>
              </div>
              <div class="menu-label" @click="openComplaint">
                <a-icon type="mail"></a-icon>
                <span class="label-text">总经理投诉通道</span>
              </div>
              <div class="menu-label" @click="openChangePwd">
                <a-icon type="form"></a-icon>
                <span class="label-text">修改密码</span>
              </div>
              <div class="menu-label" @click="openHelp">
                <a-icon type="question-circle"></a-icon>
                <span class="label-text">帮助</span>
              </div>
            </div>
          </div>
        </template>
        <div class="action dropdown">
          <a-icon type="unordered-list" class="action-icon" />
          <span class="action-text"><a-icon type="caret-down" /></span>
        </div>
      </a-popover>

      <div class="action">
        <MenuUserAvatar></MenuUserAvatar>
      </div>
    </div>
    <a-modal v-model="modal.visible" title="修改密码" okText="确认修改" @ok="handleOk">
      <a-form labelAlign="left" class="flex-form form-label-5" :form="modal.form">
        <a-form-item label="新密码">
          <a-input
            v-decorator="[
              'password',
              {
                rules: [{ required: true, message: '请输入新密码' }],
              },
            ]"
            autocomplete="off"
            placeholder="请输入新密码"
          ></a-input>
        </a-form-item>
        <a-form-item label="确认密码">
          <a-input
            v-decorator="[
              'confirmPassword',
              {
                validateFirst: true,
                validateTrigger: 'blur',
                rules: [{ required: true, message: '请输入确认密码' }, { validator: validateEqual }],
              },
            ]"
            autocomplete="off"
            placeholder="请输入确认密码"
          ></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
    <Complaint ref="Complaint"></Complaint>
    <Demand ref="Demand"></Demand>
  </a-layout-header>
</template>

<script>
import MenuUserAvatar from '@/components/MenuUserAvatar'
import { toolMixin } from '@/utils/mixin'
import Complaint from './modules/Complaint'
import Demand from './modules/Demand'
export default {
  name: 'GlobalSHeader',
  mixins: [toolMixin],
  components: {
    MenuUserAvatar,
    Complaint,
    Demand,
  },
  data() {
    return {
      visible: false,
      modal: {
        visible: false,
        form: this.$form.createForm(this),
      },
      activeLevel1MenusIndex: 0,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        let activeLevel1MenusIndex = this.menus.findIndex((m) => this.isMenuActive(m))
        // 如果是隐藏菜单，直接取上一次的激活状态
        if (this.menus[activeLevel1MenusIndex].meta.hidden) {
          activeLevel1MenusIndex = this.getPrevActiveIndex()
        }
        this.activeLevel1MenusIndex = activeLevel1MenusIndex
        this.setPrevActiveIndex()
      },
    },
  },
  props: {
    menus: {
      type: Array,
      required: true,
    },
    device: {
      type: String,
      required: false,
      default: 'desktop',
    },
  },
  async mounted() {
    // this.getWeather()
    // this.getNoticeCount()
  },
  methods: {
    getPrevActiveIndex() {
      return Number(sessionStorage.getItem('activeLevel1MenusIndex') || 0)
    },
    setPrevActiveIndex() {
      sessionStorage.setItem('activeLevel1MenusIndex', this.activeLevel1MenusIndex)
    },
    openHelp() {
      const src =
        'https://van-tai-statics.oss-cn-hangzhou.aliyuncs.com/mh/%E4%B8%87%E6%B3%B0%E7%BB%9F%E4%B8%80%E9%97%A8%E6%88%B7%20%20%E6%93%8D%E4%BD%9C%E8%AF%B4%E6%98%8E.doc'
      window.open(`https://view.officeapps.live.com/op/view.aspx?src=${src}`)
    },
    openComplaint() {
      this.$refs.Complaint.start()
    },
    async openDemand() {
      await this.$refs.Demand.start()
      this.$message.success('发送成功，感谢您的反馈')
    },
    menuClick(item) {
      this.$router.push(item.path)
    },

    isMenuActive(item) {
      let flag = false
      const path = this.$route.path
      function wlk(menu) {
        if (path === menu.path) {
          flag = true
        } else {
          if (menu.children) {
            menu.children.map((m) => {
              wlk(m)
            })
          }
        }
      }
      wlk(item)
      return flag
    },
    openChangePwd() {
      this.$reset('modal')
      this.modal.visible = true
    },
    // 显示通知公告
    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            await this.$axios.post('/ums/weblogin/updatePwd', {
              password: params.password,
            })
            this.$message.success('操作成功!')
            this.modal.visible = false
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    validateEqual(rule, value, callback) {
      const password = this.modal.form.getFieldValue('password')
      if (value === password) {
        callback()
      } else {
        callback(new Error('两次输入密码不一致'))
      }
    },
  },
}
</script>
<style lang="css">
.dropdown-extra-menu {
  box-shadow: 0px 3px 15px 0px rgba(99, 155, 252, 0.2);

  padding-top: 0;
  border-radius: 4px;
}
</style>
<style lang="less" scoped>
.header {
  background: transparent;
  position: relative;
  height: 90px;
  font-size: 14px;
  padding: 0;
  .top-menu {
    height: 70px;
    background: #fff;
    box-shadow: 0px 8px 12px 0px rgba(99, 155, 252, 0.05);
  }
}
.header-extra {
  position: absolute;
  height: 40px;
  right: 0;
  top: 15px;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-right: 30px;
  .action {
    cursor: pointer;
    height: 100%;
    transition: all 0.3s;
    &:hover {
      color: #639bfc;
    }
    position: relative;
    margin-left: 8px;
    display: flex;
    align-items: center;
    .action-text {
      padding-right: 8px;
    }
    .action-icon {
      margin-right: 5px;
    }
    &.dropdown {
      &:after {
        content: '';
        position: absolute;
        height: 15px;
        bottom: 0;
        top: 0;
        right: 0;
        margin: auto;
        width: 1px;
        background: #30425b;
      }
    }

    &:last-child {
      .action-text {
        padding-right: 0;
      }
    }
  }
  .btn {
    margin-left: 15px;
    border: 1px solid rgba(99, 155, 252, 0.15);
    height: 34px;
    border-radius: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    color: #639bfc;
    cursor: pointer;
  }
}
.header-animat {
  position: relative;
  z-index: 11;
}

.top-menu {
  padding-left: 6px;
  display: flex;
  color: #fff;
  font-size: 14px;
  height: 70px;
}

.top-menu-item {
  padding: 0 7px 20px;
  width: 110px;

  height: 90px;
  padding-bottom: 20px;
  cursor: pointer;
  color: #333;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    font-size: 18px;
    font-weight: bold;
    color: #639bfc;
    width: 180px;

    background: url('./menu_item_bg.png') center center / auto 100%;
    &:after {
      content: '';
      position: absolute;
      bottom: 35px;
      width: 28px;
      height: 3px;
      border-radius: 3px;
      background: #639bfc;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  &:hover {
    color: #639bfc;
  }
}
.dropdown-extra-menu-list {
  width: 130px;
  .menu-label {
    line-height: 32px;
    .label-text {
      margin-left: 10px;
    }
    &:hover {
      color: #639bfc;
    }
  }
}
</style>
