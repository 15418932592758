const payloadAdaptor = (jwtPayload) => {
  const base = JSON.parse(jwtPayload)
  console.log(base)
  return {
    nickname: base.user_name,
    phone: base.phone,
    jobName: base.job_name || '',
    deptName: base.dept_name || '',
    workcode: base.workcode,
  }
}
export const parseJwt = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jwtPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return payloadAdaptor(jwtPayload)
}
