<template>
  <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk" @cancel="handleCancel">
    <a-form class="flex-form form-label-5" labelAlign="left" :form="modal.form">
      <div class="mb-4">请将您在使用过程中的建议和意见反馈给我们，我们会尽快处理</div>
      <a-form-item label="问题分类">
        <a-select
          :options="typesMap.type"
          v-decorator="[
            'type',
            {
              rules: [{ required: true, message: '请选择问题分类' }],
            },
          ]"
          placeholder="请选择问题分类"
        ></a-select>
      </a-form-item>
      <a-form-item label="需求描述">
        <a-textarea
          v-decorator="[
            'remark',
            {
              rules: [{ required: true, message: '请输入需求内容' }],
            },
          ]"
          autocomplete="off"
          placeholder="请输入需求内容"
          :autoSize="{ minRows: 6, maxRows: 15 }"
        ></a-textarea>
      </a-form-item>
      <a-form-item label="图片说明">
        <PictureCardUpload style="width: 300px; height: 225px" v-decorator="['picUrl']"></PictureCardUpload>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { toolMixin } from '@/utils/mixin'
import PictureCardUpload from '@/components/pictureCardUpload/PictureCardUpload'
export default {
  mixins: [toolMixin],
  components: {
    PictureCardUpload,
  },
  data() {
    return {
      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '我要提需求',
        data: {},
      },
      typesMap: {
        type: [
          {
            label: '综合门户',
            value: 'DJ',
          },
          {
            label: '工作台',
            value: 'GZ',
          },
          {
            label: '数据查询',
            value: 'SJ',
          },
          {
            label: '智能BI',
            value: 'BI',
          },
          {
            label: '工期',
            value: 'GQ',
          },
          {
            label: '应用系统',
            value: 'YY',
          },
          {
            label: '其他',
            value: 'QT',
          },
        ],
      },
    }
  },
  methods: {
    async start() {
      this.$reset('modal')
      this.modal.visible = true
      return new Promise((resolve, reject) => {
        this.$once('end', function (state, result) {
          if (state === 'ok') {
            resolve(result)
          }
          if (state === 'cancel') {
            reject(new Error('E_CANCEL'))
          }
          this.modal.visible = false
        })
      })
    },
    async handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            await this.$axios.post('/unify-home/home/menu/saveJsonIdea', params)
            this.$emit('end', 'ok')
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    handleCancel() {
      this.$emit('end', 'cancel')
    },
  },
}
</script>

<style scoped lang="less">
.indent-2 {
  text-indent: 2em;
}
</style>
