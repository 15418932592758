import axiosInstance from '@/plugins/axios'
// 需要mock的，需要把地址暴露，并在mock模块引用
export const SYSTEM_API_LOGIN = '/ums/weblogin/loginByPwd'
export const SYSTEM_API_REFRESHTOKEN = '/ums/weblogin/refreshToken'
export const SYSTEM_API_GET_AUTH = '/unify-home/home/menu/homelistauth'

export const login = async ({ username, password, rememberMe, code }) => {
  const res = await axiosInstance.post(SYSTEM_API_LOGIN, {
    loginname: username,
    password: password,
  })
  rememberMe ? localStorage.setItem('remember_me', '1') : localStorage.removeItem('remember_me')
  return res.data
}

export const doRefreshToken = async ({ refreshToken }) => {
  const res = await axiosInstance.post(SYSTEM_API_REFRESHTOKEN, { refreshToken })
  return res.data
}

export const getInfo = async () => {
  const res = await axiosInstance.post(SYSTEM_API_GET_AUTH)
  return {
    roles: {
      permission: {
        menus: res.data,
      },
    },
  }
}
