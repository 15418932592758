var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":_vm.modal.title},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel},model:{value:(_vm.modal.visible),callback:function ($$v) {_vm.$set(_vm.modal, "visible", $$v)},expression:"modal.visible"}},[_c('a-form',{staticClass:"flex-form form-label-5",attrs:{"labelAlign":"left","form":_vm.modal.form}},[_c('div',{staticClass:"mb-4"},[_vm._v("请将您在使用过程中的建议和意见反馈给我们，我们会尽快处理")]),_c('a-form-item',{attrs:{"label":"问题分类"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'type',
          {
            rules: [{ required: true, message: '请选择问题分类' }],
          } ]),expression:"[\n          'type',\n          {\n            rules: [{ required: true, message: '请选择问题分类' }],\n          },\n        ]"}],attrs:{"options":_vm.typesMap.type,"placeholder":"请选择问题分类"}})],1),_c('a-form-item',{attrs:{"label":"需求描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remark',
          {
            rules: [{ required: true, message: '请输入需求内容' }],
          } ]),expression:"[\n          'remark',\n          {\n            rules: [{ required: true, message: '请输入需求内容' }],\n          },\n        ]"}],attrs:{"autocomplete":"off","placeholder":"请输入需求内容","autoSize":{ minRows: 6, maxRows: 15 }}})],1),_c('a-form-item',{attrs:{"label":"图片说明"}},[_c('PictureCardUpload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['picUrl']),expression:"['picUrl']"}],staticStyle:{"width":"300px","height":"225px"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }