import { setDocumentTitle } from '@/utils/domUtil'
import { is401 } from '@/plugins/axios/configFns'
import { constantRouterMap } from '@/routes'
import treeUtils from '@/utils/treeUtils'
const whiteList = [] // no redirect whitelist
treeUtils.forEach(constantRouterMap, (item) => {
  const whiteMenu = item.redirect || item.path
  if (!whiteList.includes(whiteMenu)) {
    whiteList.push(whiteMenu)
  }
})
export default function (context) {
  const { app, router, store } = context
  // eslint-disable-next-line
  const { $progress, $popErrorPage } = app

  router.beforeEach(async (to, from, next) => {
    $progress.start() // start progress bar
    to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(to.meta.title)

    if (whiteList.includes(to.path)) {
      // 在免登录白名单，直接进入
      next()
    } else {
      if (store.state.user.auth.accessToken) {
        /* has token */

        if (!store.state.permission.loaded) {
          try {
            // 根据用户信息生成可访问的路由表
            await Promise.all([
              store.dispatch('SetRoute'),
              store.dispatch('UpdateCollectionMenus'),
              store.dispatch('UpdateTodoList'),
            ])
            // addroute 会导致next丢失，必须显示添加to
            // 先跳转，然后强制更新
            next(to)
            setTimeout(() => {
              store.dispatch('SetRoute', true)
            })
          } catch (e) {
            console.log('[GetInfo fail]', e)
            next()
            // 重定向异常，这里不需要处理，全局请求封装有处理
            if (!is401(e)) {
              $popErrorPage(e.message)
            }
          }
        } else {
          next()
        }
      } else {
        next({ path: '/user/login', query: { redirect: to.fullPath }, replace: true })
        $progress.done() // if current page is login will not trigger afterEach hook, so manually handle it
      }
    }
  })
  router.afterEach(() => {
    $progress.done() // finish progress bar
  })
}
