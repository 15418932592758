<template>
  <a-layout :class="['layout h-full', $device]">
    <!-- siteMenu -->
    <side-menu :menus="sideMenus" v-if="isSiteMenu"></side-menu>
    <!-- workMenu -->
    <side-work-menu v-else></side-work-menu>
    <a-layout style="background: #f3f5fa" class="relative">
      <global-header :device="$device" :menus="menus" />
      <a-layout-content class="flex-1" id="watermarkParent">
        <div class="content-main-holder">
          <transition name="page-transition">
            <KeepAliveRouterView />
          </transition>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { mapState } from 'vuex'
import { AppDeviceEnquire } from '@/utils/mixin'
import KeepAliveRouterView from '@/components/KeepAliveRouterView'
import { SideMenu, SideWorkMenu } from '@/components/sideMenu'
import GlobalHeader from '@/components/GlobalHeader'
export default {
  name: 'BasicLayout',
  mixins: [AppDeviceEnquire],
  components: {
    KeepAliveRouterView,
    SideWorkMenu,
    SideMenu,
    GlobalHeader,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      menus: (state) => state.permission.menus,
      nickname: (state) => state.user.nickname,
      phone: (state) => state.user.phone,
    }),
    activeMenusIndex() {
      let index = -1
      for (let i = 0; i < this.menus.length; i++) {
        if (this.isMenuActive(this.menus[i])) {
          index = i
          break
        }
      }
      return index
    },
    sideMenus() {
      return this.menus[this.activeMenusIndex]?.children || []
    },
    isSiteMenu() {
      let flag = true
      const code = this.menus[this.activeMenusIndex]?.meta.code
      // 这三个code显示显示工作面板
      if (['tonghemenhu', 'gongzuotai', 'shujuchaxun', 'yingyongxitong'].includes(code)) {
        flag = false
      }
      return flag
    },
  },
  methods: {
    isMenuActive(item) {
      let flag = false
      const path = this.$route.path
      function wlk(menu) {
        if (path === menu.path) {
          flag = true
        } else {
          if (menu.children) {
            menu.children.map((m) => {
              wlk(m)
            })
          }
        }
      }
      wlk(item)
      return flag
    },
  },
  mounted() {
    setTimeout(() => {
      this.$watermark.init({
        watermark_txt: `${this.nickname} ${this.phone}`,
        watermark_width: 160,
        watermark_alpha: 0.1,
        watermark_fontsize: '12px',
        watermark_parent_node: 'watermarkParent',
        watermark_x: -45, // 水印起始位置x轴坐标
        watermark_y: -20,
        watermark_x_space: 45, // 水印x轴间隔
        watermark_y_space: 70,
      })
    })
  },
}
</script>

<style lang="less">
/*
 * The following styles are auto-applied to elements with
 * transition="page-transition" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the page transition by editing
 * these styles.
 */
.page-transition-enter {
  opacity: 0;
}
.page-transition-enter-active {
  transition: opacity 0.8s;
}
</style>
