import router, { resetAsyncRoutes } from '@/router'
import { login, doRefreshToken, getInfo } from '@/service/api/systemApi'
import axiosInstance from '@/plugins/axios'
import { parseJwt } from '@/service/jwt'
const initUserState = {
  // 认证信息
  auth: {
    accessToken: '',
    refreshToken: '',
  },
  // 用户数据
  nickname: '',
  phone: '',
  workcode: '',
  deptName: '',
  jobName: '',

  collectionMenuIds: [],
  todoList: [
    {
      title: '我的待办',
      url: '',
    },
    {
      title: '我的已办',
      url: '',
    },
    {
      title: '我的请求',
      url: '',
    },
  ],
}
const user = {
  state: initUserState,
  mutations: {
    SET_TOKEN: (state, { accessToken, refreshToken }) => {
      state.auth.accessToken = accessToken
      state.auth.refreshToken = refreshToken
    },
    SET_USER(state, user) {
      for (const i in user) {
        if (i in state) {
          state[i] = user[i]
        }
      }
    },
    RESET_USER(state) {
      for (const i in initUserState) {
        state[i] = initUserState[i]
      }
      for (const j in state) {
        if (!(j in initUserState)) {
          delete state[j]
        }
      }
    },
  },

  actions: {
    // 登录
    async Login({ commit, dispatch }, loginParams) {
      const { token } = await login(loginParams)
      const user = parseJwt(token)

      commit('SET_USER', user)
      commit('SET_TOKEN', { accessToken: token })
    },
    RefreshToken({ commit, state }) {
      return new Promise(async (resolve, reject) => {
        try {
          const { accessToken, refreshToken } = await doRefreshToken({
            refreshToken: state.auth.refreshToken,
          })
          commit('SET_TOKEN', { accessToken, refreshToken })
          resolve()
        } catch (e) {
          reject(e)
        }
      })
    },
    async SetRoute({ dispatch }, forceUpdate = false) {
      const storageUInfo = JSON.parse(localStorage.getItem('uInfo'))
      let uInfo = storageUInfo
      // 没有storageInfo或者需要强制更新时
      if (!uInfo || forceUpdate) {
        uInfo = await getInfo()
        localStorage.setItem('uInfo', JSON.stringify(uInfo))
      }
      const processedMenus = await dispatch('GenerateMenus', { menus: uInfo.roles.permission.menus })
      const routes = await dispatch('GenerateRoutes', { processedMenus })
      resetAsyncRoutes(routes)
    },
    // 登出
    Logout({ commit }, { type = 'default' } = {}) {
      return new Promise((resolve, reject) => {
        // 清理permissions  -> 清理用户数据 -> 清理动态routes(好像没有对应的api，只能通过window重载？)
        commit('RESET_PERMISSION')
        commit('RESET_USER')

        const loginPage = `/user/login`

        // 通过router登出
        if (type === 'default') {
          if (loginPage === router.currentRoute.path) {
            reject(new Error('Dispatch [Logout] in loginPage'))
            return
          }
          const to = `${loginPage}?redirect=${window.location.pathname + window.location.search}`
          router.push(to)
          resolve()
        }
        // window重载
        if (type === 'reLaunch') {
          // 这里直接跳转登录，不要通过根路径redirect到登录，因为根路径是动态绑定的menu
          window.location.href = loginPage
          resolve()
        }
        // 其他操作
        if (type === 'noJump') {
          resolve()
        }
      })
    },
    async UpdateCollectionMenus({ commit }) {
      const res = await axiosInstance.post('/unify-home/home/menu/menuIdlist')
      commit('SET_USER', { collectionMenuIds: res.data })
    },
    async UpdateTodoList({ commit, state }) {
      // oaext是走的其他系统，增加容错缓存
      let dblist = JSON.parse(localStorage.getItem('dblist')) || {}
      try {
        const res = await axiosInstance.post('/oaext/unify/dblist', {}, { showError: false })
        dblist = res.data
        localStorage.setItem('dblist', JSON.stringify(dblist))
      } catch (e) {}
      const { dbUrl, ybUrl, qqUrl } = dblist
      const todoList = [...state.todoList]
      todoList[0].url = dbUrl
      todoList[1].url = ybUrl
      todoList[2].url = qqUrl
      commit('SET_USER', { todoList })
    },
  },
}

export default user
