import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'

import createPersistedState from 'vuex-persistedstate'
// import createLogger from 'vuex/dist/logger'
// default router permission control
import permission from './modules/permission'

import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    permission,
  },
  state: {},
  mutations: {},
  actions: {},
  getters,
  plugins: [
    createPersistedState({
      paths: ['user'],
      key: 'vuex_user',
      // 记住我时，存到localstorage(cookie?),否则存到sessionStorage
      storage: {
        getItem(key) {
          return localStorage.getItem(key) || sessionStorage.getItem(key)
        },
        setItem(key, value) {
          if (localStorage.getItem('remember_me')) {
            localStorage.setItem(key, value)
            sessionStorage.getItem(key) && sessionStorage.removeItem(key)
          } else {
            sessionStorage.setItem(key, value)
            localStorage.getItem(key) && localStorage.removeItem(key)
          }
        },
        removeItem(key) {
          if (localStorage.getItem('remember_me')) {
            localStorage.removeItem(key)
          } else {
            sessionStorage.removeItem(key)
          }
        },
      },
    }),
    createPersistedState({
      paths: ['app.tab', 'app.sidebarCollapse'],
      storage: sessionStorage,
    }),
    // createLogger(),
  ],
})
