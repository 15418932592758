export default function (context) {
  const { router, store } = context
  let nextRouteChange = false
  let nextReload = false
  let cache = null
  router.afterEach((to, from) => {
    out.params = null
    if (nextRouteChange) {
      out.params = cache
    }
    nextRouteChange = false
    nextReload = false
  })
  router.beforeEach((to, from, next) => {
    if (nextReload) {
      if (to.meta.keepAlive) {
        store.commit('SET_NEXT_REFRESH_KEEP_ALIVE', to.fullPath)
      }
    }
    next()
  })
  // 传参到下个路由的activated
  const setNextParams = (params) => {
    nextRouteChange = true
    cache = params
  }
  const reloadNext = () => {
    nextReload = true
  }
  const reload = (path = router.currentRoute.fullPath) => {
    store.dispatch('ReloadRoute', path)
  }
  const remove = (path = router.currentRoute.fullPath) => {
    store.dispatch('RemoveKeepAlive', path)
  }
  const out = {
    setNextParams,
    reloadNext,
    reload,
    remove,
    params: null,
  }
  return out
}
