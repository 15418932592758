<template>
  <div class="picture-card upload-wrapper">
    <div class="upload-main-container" :class="{ disabled: disabled }" :style="style">
      <div class="upload-delete-btn" @click="deleteImg" v-if="allowClear && value && !disabled">
        <svg version="1.1" style="stroke: rgb(255, 255, 255); stroke-width: 4" viewBox="0 0 30 30">
          <path stroke-linecap="round" d="M5 25 L25 5" />
          <path stroke-linecap="round" d="M5 5 L25 25" />
        </svg>
      </div>
      <a-upload
        class="upload-main"
        listType="picture-card"
        :disabled="disabled"
        :showUploadList="false"
        :beforeUpload="upload"
      >
        <div v-if="sourcePath" class="img-container">
          <div class="type-container type-video" v-if="getFileType(sourcePath) === 'video'">
            <div class="shroud">
              <div class="play-icon">
                <svg
                  class="icon-main"
                  version="1.1"
                  fill="#fff"
                  viewBox="0 0 36 36"
                  data-play="M 12,26 18.5,22 18.5,14 12,10 z M 18.5,22 25,18 25,18 18.5,14 z"
                  data-pause="M 12,26 16.33,26 16.33,10 12,10 z M 20.66,26 25,26 25,10 20.66,10 z"
                >
                  <path d="M 12,26 18.5,22 18.5,14 12,10 z M 18.5,22 25,18 25,18 18.5,14 z" />
                </svg>
              </div>
            </div>
            <auto-img
              style="width: 100%; height: 100%"
              :src="sourcePath + '?x-oss-process=video/snapshot,t_0,f_jpg'"
              :preview="preview"
              :fit="fit"
            />
          </div>
          <div class="type-container type-img" v-else-if="getFileType(sourcePath) === 'image'">
            <auto-img style="width: 100%; height: 100%" :src="sourcePath" :preview="preview" :fit="fit" />
          </div>
          <div class="type-container type-other" v-else>
            <a class="preview-link" @click="previewTypeOther(sourcePath)">{{ getFileName(sourcePath) }}</a>
          </div>
        </div>
        <div v-else>
          <div v-if="loading">
            <a-icon :type="'loading'" />
          </div>
          <div v-else>
            <!--  注意cdn引入的vue 和 vue-template-compile版本不一致时，slot默认值无法显示 -->
            <slot v-if="$slots.default"></slot>
            <div v-else>
              <a-icon type="plus" />
              <div class="ant-upload-text">点击上传</div>
            </div>
          </div>
        </div>
      </a-upload>
    </div>
  </div>
</template>
<script>
import { ossUpload } from '@/service/oss'
import moment from 'moment'
export default {
  data() {
    return {
      loading: false,
      outerStyle: {},
      observer: null,
    }
  },
  computed: {
    sourcePath() {
      return this.value
    },
    style() {
      const { itemHeight, itemWidth, outerStyle } = this
      const { width = '148px', height = '148px' } = outerStyle
      const style = {
        height: itemHeight ? parseFloat(itemHeight) + 'px' : height,
        width: itemWidth ? parseFloat(itemWidth) + 'px' : width,
      }
      return style
    },
  },
  props: {
    // value 不可设置默认值, 否则v-decrator警告: xxx default value can not collect, please use option.initialValue to set default value.`
    // eslint-disable-next-line
    value: {
      type: String,
    },
    storeAs: {
      type: Function,
      default(file, key = '') {
        const suffix = file.name.match(/\.[^ .]+$/)[0]
        return `${key}/${moment().format('YYYY/MM')}/${new Date().getTime()}${suffix}`
      },
    },
    fit: {
      type: String,
      default: '',
    },
    preview: {
      type: [Boolean, Object],
      default: false,
    },
    itemWidth: {
      type: [String, Number],
      default: '',
    },
    itemHeight: {
      type: [String, Number],
      default: '',
    },
    // 只限制上传，不限制展示
    fileType: {
      type: String,
      default: 'image',
    },
    beforeUpload: {
      type: Function,
      default: () => {},
    },
    maxFileSize: {
      type: Number,
      default: 2,
    },
    allowClear: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.outerStyle = this.getOuterStyle()
    this.observer = new MutationObserver(() => {
      this.outerStyle = this.getOuterStyle()
    })
    this.observer.observe(this.$el, {
      attributes: true,
    })
  },
  destroyed() {
    this.observer.disconnect()
  },
  methods: {
    getOuterStyle() {
      let i = 0
      const style = {}
      while (this.$el?.style[i]) {
        const outerStyleItemName = this.$el.style[i]
        style[outerStyleItemName] = this.$el.style[outerStyleItemName]
        i++
      }
      return style
    },
    getFileName(src) {
      return src.replace(/(.*\/)*([^.]+)/gi, '$2').replace(/\?.+/, '')
    },
    // 推测的文件类型
    getFileType(sourcePath) {
      let fileType = ''
      // 后缀获取
      let suffix = ''
      try {
        const flieArr = new URL(sourcePath).pathname.split('.')
        suffix = flieArr[flieArr.length - 1].toLowerCase()
      } catch (err) {
        suffix = ''
      }
      const imageList = ['png', 'jpg', 'jpeg', 'bmp', 'gif']
      if (imageList.includes(suffix)) {
        fileType = 'image'
      }
      const videoList = ['mp4', 'm2v', 'mkv']
      if (videoList.includes(suffix)) {
        fileType = 'video'
      }
      const wordList = ['docx', 'doc']
      if (wordList.includes(suffix)) {
        fileType = 'word'
      }
      return fileType
    },
    upload(file) {
      const { fileType, maxFileSize } = this
      const [type] = file.type.split('/')

      if (fileType) {
        const allowType = fileType.split(',')
        if (!allowType.includes(type)) {
          this.$message.error(`请上传${allowType}类型文件`)
          return false
        }
      }
      const isLtSize = file.size / 1024 / 1024 < maxFileSize
      if (!isLtSize) {
        this.$message.error(`上传文件不能大于${maxFileSize}M!`)
        return false
      }
      // 已在内部实现上传，需要禁用默认上传
      const doUpload = () => {
        this.loading = true

        const storeAs = this.storeAs(file)
        ossUpload(file, storeAs)
          .then((path) => {
            this.$emit('input', path)
            this.$emit('change', path)
          })
          .catch((e) => {
            if (this.$listeners.uploadFail) {
              this.$emit('uploadFail', e)
            } else {
              console.error(e)
              this.$message.error('上传失败')
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
      if (this.beforeUpload) {
        const res = this.beforeUpload(file)
        if (res === false) {
          return false
        }
        if (res instanceof Promise) {
          return (
            res
              .then(doUpload)
              // return Promise.reject 禁用默认上传
              .then(() => Promise.reject(new Error('STOP_ANT_DEFAULT_UPLOAD')))
          )
        }
      }

      doUpload()
      // return false 禁用默认上传
      return false
    },
    previewTypeOther(sourcePath) {
      window.open(sourcePath)
    },
    deleteImg() {
      this.$emit('input', '')
      this.$emit('change', '')
    },
  },
}
</script>

<style lang="less" scoped>
.upload-wrapper {
  position: relative;
  .upload-delete-btn {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    background: rgba(0, 0, 0, 0.15);
    padding: 2px;
    > svg {
      display: block;
    }
  }
  .upload-main-container {
    position: relative;
    &.disabled {
      cursor: not-allowed;

      .type-container-bg-wrapper {
        pointer-events: none;
      }
    }

    .upload-main {
      width: 100%;
      height: 100%;

      .img-container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .type-container {
        width: 100%;
        height: 100%;
        position: relative;
      }
    }
  }

  .upload-extra-container {
    // display: flex;
    position: absolute;
    top: 0;
    width: 0;

    .extra-row {
      margin-bottom: 10px;
    }
    &.small {
      .extra-row {
        margin-bottom: 5px;
      }
    }
  }
}
.type-video {
  position: relative;
  .shroud {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    .play-icon {
      width: 40%;
      padding-top: 40%;
      border: 1px solid #fff;
      border-radius: 50%;
      position: relative;
      .icon-main {
        width: 100%;
        height: 100%;
        padding-left: 10%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
.type-other {
  display: flex;
  align-items: center;
  justify-content: center;
  .preview-link {
    display: block;
    word-break: break-all;
    max-width: 80%;
  }
}
</style>
<style scoped>
.upload-main >>> .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 100%;
  margin: 0;
}
.upload-main >>> .ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0;
  display: table-cell;
  position: relative;
}
</style>
