<template>
  <div>
    <a-popover
      v-model="visible"
      placement="bottomRight"
      overlayClassName="menu-user-avatar-pop"
      :autoAdjustOverflow="true"
      :arrowPointAtCenter="true"
    >
      <template slot="content">
        <div class="avatar-menu">
          <div class="avatar-menu-item" @click="handleLogout">
            <div class="menu-label">
              <span class="label-text">{{ uInfo }}</span>
            </div>
          </div>
          <div class="cursor-pointer avatar-menu-item" @click="handleLogout">
            <div class="menu-label">
              <span class="label-text">退出登陆</span>
            </div>
          </div>
        </div>
      </template>
      <div @click="onClickAvatar" class="avatar" ref="avatarWrapper">
        <a-avatar style="background: #aabbd8">{{ nickname.slice(nickname.length - 2, nickname.length) }}</a-avatar>
      </div>
    </a-popover>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      visible: false,
      modal: {
        visible: false,
        form: this.$form.createForm(this),
      },
    }
  },
  computed: {
    ...mapGetters(['avatar', 'nickname']),
    uInfo() {
      const arr = [
        this.$store.state.user.deptName,
        this.$store.state.user.jobName,
        this.$store.state.user.nickname,
      ].filter((v) => !!v)
      return arr.join(' ')
    },
  },
  methods: {
    ...mapActions(['Logout']),
    onClickAvatar() {
      this.visible = !this.visible
    },

    handleLogout() {
      this.$confirm({
        title: '提示',
        content: '真的要注销登录吗 ?',
        onOk: () => {
          this.$store.commit('RESET_PERMISSION')
          return this.Logout({ type: 'reLaunch' }).catch((err) => {
            this.$message.error({
              title: '错误',
              description: err.message,
            })
          })
        },
        onCancel() {},
      })
    },
  },
}
</script>

<style lang="less" scoped>
.avatar-menu {
  margin: -8px 0;
  .avatar-menu-item {
    height: 42px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f4f4f4;
    &.cursor-pointer:hover {
      color: #639bfc;
    }
    &:last-child {
      border-bottom: 0;
    }
    .menu-label {
      display: flex;
      .anticon {
        color: @primary-color;
        margin-right: 13px;
        font-size: 18px;
      }
      align-items: center;
    }
    .menu-content {
      color: #999;
    }
  }
}
.avatar {
  display: flex;
  line-height: 32px;
}
</style>
